.list-header.margin-header {
  margin-top: 2rem; }

#moreInfoSeoLink {
  padding-left: 10px;
  display: inline-block; }

div.custom-result-content {
  margin: 0 0 20px 0; }

div.no-results .search-suggestions ul.popular-searches {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 1em 0 0; }
  div.no-results .search-suggestions ul.popular-searches li {
    position: relative;
    float: left;
    margin: 0 10px 10px 0;
    line-height: 1.4em;
    letter-spacing: 1px;
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 6px 8px 6px 12px;
    transition: .3s; }

.refineParams {
  margin: 2rem 0 2rem 0; }
  .refineParams.bottomParams {
    margin: 2rem 0; }
  .refineParams.pagination-options {
    width: 100%; }
    .refineParams.pagination-options .btn-group.show .btn i.ra-icon {
      transform: rotate(-180deg); }
    .refineParams.pagination-options .btn-group.pagination-group {
      width: 100%; }
      .refineParams.pagination-options .btn-group.pagination-group ol {
        margin: 0;
        width: 100%;
        justify-content: center; }
        .refineParams.pagination-options .btn-group.pagination-group ol li {
          display: flex;
          line-height: 3rem;
          align-self: center; }
          .refineParams.pagination-options .btn-group.pagination-group ol li.prev, .refineParams.pagination-options .btn-group.pagination-group ol li.next {
            justify-content: center; }
          .refineParams.pagination-options .btn-group.pagination-group ol li.pagination-button {
            display: flex; }
          .refineParams.pagination-options .btn-group.pagination-group ol li.pagination-text {
            display: flex;
            align-items: center;
            justify-content: center; }
          .refineParams.pagination-options .btn-group.pagination-group ol li i.ra-icon {
            align-self: center; }
          .refineParams.pagination-options .btn-group.pagination-group ol li i.chevron-left {
            margin-right: 0.5rem; }
          .refineParams.pagination-options .btn-group.pagination-group ol li i.chevron-right {
            margin-left: 0.5rem; }
          .refineParams.pagination-options .btn-group.pagination-group ol li a {
            display: flex;
            justify-content: center; }
            @media (min-width: 360px) {
              .refineParams.pagination-options .btn-group.pagination-group ol li a {
                min-width: 17rem; } }
          .refineParams.pagination-options .btn-group.pagination-group ol li.disabled a {
            cursor: not-allowed;
            opacity: 0.3; }
    @media (min-width: 576px) {
      .refineParams.pagination-options .pagination-group-mobile {
        display: none; } }
    @media (max-width: 575.98px) {
      .refineParams.pagination-options .pagination-group-window {
        display: none; } }
    .refineParams.pagination-options .article-count {
      display: flex;
      justify-content: flex-start;
      vertical-align: middle;
      align-items: center;
      height: 4rem;
      line-height: 1; }
    .refineParams.pagination-options .pagination.group-similar {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      padding: 0 .5rem; }
    .refineParams.pagination-options .btn-list-articles-settings {
      display: flex;
      justify-content: flex-start;
      vertical-align: middle;
      align-items: center; }
      .refineParams.pagination-options .btn-list-articles-settings label.slider-switch {
        margin: 0 0 0 1rem;
        display: block;
        cursor: pointer; }
      .refineParams.pagination-options .btn-list-articles-settings label.switch {
        position: relative;
        top: unset;
        transform: none;
        right: unset; }
    .refineParams.pagination-options .list-sort {
      padding: 0; }
      @media (max-width: 575.98px) {
        .refineParams.pagination-options .list-sort {
          margin-top: 2rem; } }
      .refineParams.pagination-options .list-sort .sort-group .dropdown .btn-dropdown-sort {
        border: 1px solid transparent;
        padding: .5rem 2.5rem .5rem .5rem;
        text-align: left;
        overflow: hidden;
        line-height: 1;
        height: 4rem;
        white-space: normal; }
        .refineParams.pagination-options .list-sort .sort-group .dropdown .btn-dropdown-sort:hover {
          background-color: #e3e3e3;
          border: 1px solid #aeaeae; }
        .refineParams.pagination-options .list-sort .sort-group .dropdown .btn-dropdown-sort.dropdown-toggle::after {
          display: none; }
        .refineParams.pagination-options .list-sort .sort-group .dropdown .btn-dropdown-sort.dropdown-toggle i.ra-icon {
          position: absolute;
          top: 1.25rem;
          right: .5rem;
          transition: .3s; }
        .refineParams.pagination-options .list-sort .sort-group .dropdown .btn-dropdown-sort .sort {
          font-weight: 700;
          line-height: inherit;
          height: inherit;
          align-self: center;
          padding-left: .5rem; }
        .refineParams.pagination-options .list-sort .sort-group .dropdown .btn-dropdown-sort .sort-value {
          line-height: inherit;
          height: inherit;
          align-self: center;
          padding-right: 0;
          text-align: right; }
          @media (min-width: 576px) and (max-width: 991.98px) {
            .refineParams.pagination-options .list-sort .sort-group .dropdown .btn-dropdown-sort .sort-value {
              text-align: left; } }
      .refineParams.pagination-options .list-sort .sort-group .dropdown.show {
        cursor: pointer; }
        .refineParams.pagination-options .list-sort .sort-group .dropdown.show [data-toggle="dropdown"] {
          background-color: #575757;
          border: 1px solid #e3e3e3;
          color: #FFFFFF !important; }
          .refineParams.pagination-options .list-sort .sort-group .dropdown.show [data-toggle="dropdown"] i.ra-icon.arrowdown {
            transform: rotate(180deg); }
        .refineParams.pagination-options .list-sort .sort-group .dropdown.show .btn-dropdown-filter.dropdown-toggle i .ra-icon {
          position: absolute;
          right: 0; }
        .refineParams.pagination-options .list-sort .sort-group .dropdown.show .dropdown-menu {
          width: 100%;
          border-color: #e3e3e3;
          border-bottom-color: #575757 !important;
          border-bottom-width: 2px !important;
          margin-top: -.1rem !important; }
          .refineParams.pagination-options .list-sort .sort-group .dropdown.show .dropdown-menu[x-placement="top-start"] {
            border-color: #e3e3e3;
            border-top-color: #575757 !important;
            border-bottom-width: 1px !important;
            border-top-width: 2px !important;
            margin-top: 0; }
    .refineParams.pagination-options .dropdown-menu {
      width: 100%;
      padding: 0;
      font-size: 1.4rem;
      font-weight: 700;
      color: #575757;
      margin: 0 !important; }
      .refineParams.pagination-options .dropdown-menu button {
        background: #e3e3e3; }
      .refineParams.pagination-options .dropdown-menu ul {
        list-style: none;
        margin-bottom: 0;
        padding: 1rem;
        font-weight: 400; }
        .refineParams.pagination-options .dropdown-menu ul li {
          margin-top: .5rem;
          padding-top: .5rem;
          border-top: 1px solid #e3e3e3; }
          .refineParams.pagination-options .dropdown-menu ul li a {
            display: block; }
          .refineParams.pagination-options .dropdown-menu ul li:first-child {
            margin-top: 0;
            padding-top: 0;
            border: none; }
        .refineParams.pagination-options .dropdown-menu ul i.ra-icon {
          transform: rotate(-90deg);
          margin: 0 5px 0 0; }
          .refineParams.pagination-options .dropdown-menu ul i.ra-icon.user, .refineParams.pagination-options .dropdown-menu ul i.ra-icon.star-full, .refineParams.pagination-options .dropdown-menu ul i.ra-icon.star-empty, .refineParams.pagination-options .dropdown-menu ul i.ra-icon.star-half {
            color: inherit;
            transform: rotate(0deg); }
      .refineParams.pagination-options .dropdown-menu.show {
        border-bottom-color: #575757 !important;
        cursor: pointer; }
    .refineParams.pagination-options .sortlabel {
      text-align: right; }
    .refineParams.pagination-options button {
      margin: 0;
      padding: 0 20px 0 0;
      font-weight: normal;
      text-align: left;
      background: transparent; }
      .refineParams.pagination-options button.dropdown-toggle:after {
        display: none; }
      .refineParams.pagination-options button.dropdown-toggle i.ra-icon {
        position: absolute;
        right: .5rem;
        top: .5rem; }

.list-container .row.gridView .productBox {
  padding: 0;
  border: 1px solid #e3e3e3;
  margin: -1px 0 0 -1px; }

@media (max-width: 767.98px) {
  .desktopSort {
    display: none; } }

.mobileSort {
  display: none; }
  .mobileSort.show {
    overflow: hidden; }
    .mobileSort.show .btn-sort-mobile {
      color: #FFFFFF !important;
      background-color: #575757 !important; }
      .mobileSort.show .btn-sort-mobile i {
        transform: rotate(180deg) translateY(50%) !important; }
  .mobileSort .sort-group {
    margin: 0.5rem 0 0 0;
    padding: 0 0.5rem; }
    .mobileSort .sort-group .dropdown-menu {
      width: 100%;
      font-size: 1.4rem;
      padding: 0; }
      .mobileSort .sort-group .dropdown-menu .btn {
        height: 3.5rem;
        margin: 0; }
      .mobileSort .sort-group .dropdown-menu a {
        display: flex;
        align-items: center; }
        .mobileSort .sort-group .dropdown-menu a i {
          transform: rotate(270deg);
          margin: auto 0.5rem auto 0; }
          .mobileSort .sort-group .dropdown-menu a i.star-empty, .mobileSort .sort-group .dropdown-menu a i.user {
            transform: rotate(0deg); }
  .mobileSort .btn-sort-mobile {
    display: flex;
    border: 1px solid #e3e3e3;
    padding: 0.5rem 6rem 0.5rem 1rem;
    font-weight: 700;
    height: 4rem;
    text-align: left;
    overflow: hidden; }
    .mobileSort .btn-sort-mobile i {
      position: absolute;
      top: 50%;
      right: 0.5rem;
      transition: .3s;
      transform: translateY(-50%) !important; }
    .mobileSort .btn-sort-mobile:hover {
      background-color: #e3e3e3;
      border: 1px solid #575757; }
    .mobileSort .btn-sort-mobile::after {
      display: none; }
  @media (max-width: 767.98px) {
    .mobileSort {
      display: block; } }

.filters {
  margin: 1rem 0 0 0; }
  .filters .filter {
    margin: 0 0 1rem 0;
    padding: 0 .5rem; }
    .filters .filter .dropdown .dropdown-menu {
      width: 100%;
      min-height: 100px;
      background: transparent;
      padding: 0;
      font-size: 1.4rem;
      font-weight: bold;
      color: #575757; }
      .filters .filter .dropdown .dropdown-menu button {
        background: #e3e3e3;
        font-weight: bold; }
        .filters .filter .dropdown .dropdown-menu button:focus {
          outline: none; }
        .filters .filter .dropdown .dropdown-menu button.btn-reset-filter {
          background-color: transparent;
          border: none;
          height: 3rem;
          line-height: 1;
          font-size: 1.2rem;
          margin: 0 0 -1rem 0; }
          .filters .filter .dropdown .dropdown-menu button.btn-reset-filter i {
            font-size: 1.5rem;
            vertical-align: middle; }
      .filters .filter .dropdown .dropdown-menu ul {
        background: #FFFFFF;
        margin: 0;
        max-height: 26rem;
        overflow: auto;
        list-style: none;
        padding: 0 0 1rem 1rem;
        display: flex;
        flex-direction: column; }
        .filters .filter .dropdown .dropdown-menu ul li {
          position: relative;
          margin: 0;
          padding: .5rem 0 .75rem 0;
          border-bottom: 1px solid #e3e3e3;
          order: 1; }
          .filters .filter .dropdown .dropdown-menu ul li:last-child {
            border: none; }
          .filters .filter .dropdown .dropdown-menu ul li.na, .filters .filter .dropdown .dropdown-menu ul li.no-hits {
            order: 2; }
            .filters .filter .dropdown .dropdown-menu ul li.na:first-of-type, .filters .filter .dropdown .dropdown-menu ul li.no-hits:first-of-type {
              border-top: 1px solid #e3e3e3; }
            .filters .filter .dropdown .dropdown-menu ul li.na span.filtertext, .filters .filter .dropdown .dropdown-menu ul li.no-hits span.filtertext {
              opacity: 0.3;
              cursor: default; }
            .filters .filter .dropdown .dropdown-menu ul li.na span.color, .filters .filter .dropdown .dropdown-menu ul li.no-hits span.color {
              cursor: not-allowed !important; }
          .filters .filter .dropdown .dropdown-menu ul li .filter-inner {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center; }
            .filters .filter .dropdown .dropdown-menu ul li .filter-inner .filter-doc-count {
              text-align: right;
              font-size: 1.2rem;
              font-style: italic;
              font-weight: normal;
              align-self: baseline;
              margin-top: .15rem; }
          .filters .filter .dropdown .dropdown-menu ul li.selected span.checkbox .ra-icon.check {
            display: inline; }
          .filters .filter .dropdown .dropdown-menu ul li.active {
            order: 0; }
          .filters .filter .dropdown .dropdown-menu ul li .filterhint {
            margin: 0 0 0 26px;
            font-size: 0.75em;
            font-weight: normal; }
          .filters .filter .dropdown .dropdown-menu ul li label.slider-switch {
            margin: 0;
            display: block;
            cursor: pointer; }
          .filters .filter .dropdown .dropdown-menu ul li div {
            padding: 0; }
            .filters .filter .dropdown .dropdown-menu ul li div:first-child {
              min-width: 26px;
              max-width: 26px; }
            .filters .filter .dropdown .dropdown-menu ul li div span {
              vertical-align: middle;
              cursor: pointer;
              display: inline-block;
              max-width: 158px; }
              .filters .filter .dropdown .dropdown-menu ul li div span.checkbox {
                width: 20px;
                height: 20px;
                display: inline-block;
                margin: 0;
                border: 1px solid #575757; }
                .filters .filter .dropdown .dropdown-menu ul li div span.checkbox .ra-icon.check {
                  display: none;
                  margin-left: 2px;
                  color: #747474;
                  font-weight: bold; }
                .filters .filter .dropdown .dropdown-menu ul li div span.checkbox.checked .ra-icon.check {
                  display: inline; }
      .filters .filter .dropdown .dropdown-menu .wrapper {
        padding: 10px;
        background: #fff;
        cursor: default; }
    .filters .filter .dropdown .btn-dropdown-filter {
      border: 1px solid #e3e3e3;
      padding: .5rem 6rem .5rem 1rem;
      font-weight: bold;
      text-align: left;
      overflow: hidden;
      line-height: 1;
      height: 4rem;
      white-space: normal; }
      .filters .filter .dropdown .btn-dropdown-filter.dropdown-toggle::after {
        display: none; }
      .filters .filter .dropdown .btn-dropdown-filter.dropdown-toggle i.ra-icon {
        position: absolute;
        top: 50%;
        right: .5rem;
        transition: .3s;
        transform: translateY(-50%); }
        .filters .filter .dropdown .btn-dropdown-filter.dropdown-toggle i.ra-icon.info {
          font-size: 1.5rem;
          right: 2.5rem; }
      .filters .filter .dropdown .btn-dropdown-filter.boolean {
        padding: .5rem 4rem .5rem 1rem; }
        .filters .filter .dropdown .btn-dropdown-filter.boolean label.slider-switch {
          margin: 0;
          display: block;
          cursor: pointer; }
        .filters .filter .dropdown .btn-dropdown-filter.boolean i.info {
          right: 4rem !important; }
      .filters .filter .dropdown .btn-dropdown-filter:hover {
        border: 1px solid #aeaeae;
        background-color: #e3e3e3; }
      .filters .filter .dropdown .btn-dropdown-filter .title {
        display: block; }
      .filters .filter .dropdown .btn-dropdown-filter .filter-indicator {
        background-color: #575757;
        color: #FFFFFF;
        height: 2rem;
        min-width: 2rem;
        padding: 0 .5rem;
        line-height: 2rem;
        border-radius: .5rem;
        text-align: center;
        display: inline-block;
        position: absolute;
        right: 2.5rem;
        top: 50%;
        transform: translateY(-50%); }
        .filters .filter .dropdown .btn-dropdown-filter .filter-indicator i {
          font-size: 1rem;
          line-height: 1;
          vertical-align: middle;
          position: relative !important;
          top: unset !important;
          right: unset !important;
          transform: none !important; }
    .filters .filter .dropdown.show {
      cursor: pointer; }
      .filters .filter .dropdown.show [data-toggle="dropdown"] {
        background-color: #575757;
        border: 1px solid #e3e3e3;
        color: #FFFFFF !important; }
        .filters .filter .dropdown.show [data-toggle="dropdown"] i.ra-icon.arrowdown {
          transform: rotate(180deg) translateY(50%); }
      .filters .filter .dropdown.show .dropdown-menu {
        width: 100%;
        border-color: #e3e3e3;
        border-bottom-color: #575757 !important;
        border-bottom-width: 2px !important;
        margin-top: -.1rem; }
        .filters .filter .dropdown.show .dropdown-menu[x-placement="top-start"] {
          border-color: #e3e3e3;
          border-top-color: #575757 !important;
          border-bottom-width: 1px !important;
          border-top-width: 2px !important;
          margin-top: 0; }
      .filters .filter .dropdown.show .filter-indicator {
        background-color: #FFFFFF;
        color: #575757; }
    .filters .filter.price #priceSlider div {
      outline: none; }
    .filters .filter.price .form-group.row {
      margin: 16px 0; }
      .filters .filter.price .form-group.row label {
        border: 1px solid #aeaeae;
        border-left: none;
        background: #efefef;
        margin-right: 10px; }
        .filters .filter.price .form-group.row label:last-child {
          margin-right: 0; }
      .filters .filter.price .form-group.row .wrapper {
        padding: 0; }
        .filters .filter.price .form-group.row .wrapper #priceMax:focus, .filters .filter.price .form-group.row .wrapper #priceMin:focus {
          outline: none;
          box-shadow: none; }
        .filters .filter.price .form-group.row .wrapper input {
          padding: 0 2px 0 5px;
          text-align: right;
          float: right;
          width: 54px;
          border: 1px solid #aeaeae;
          border-right: none;
          outline: none; }
          .filters .filter.price .form-group.row .wrapper input:focus {
            outline: none; }
        .filters .filter.price .form-group.row .wrapper .currency-symbol {
          width: auto;
          float: right;
          height: 34px;
          line-height: 32px;
          border: 1px solid #aeaeae;
          border-left: none;
          padding-right: 6px; }
        .filters .filter.price .form-group.row .wrapper:first-child {
          padding: 0 0 0 0;
          margin: 0; }
          .filters .filter.price .form-group.row .wrapper:first-child input {
            padding: 0 2px 0 5px;
            text-align: right;
            float: left;
            width: 54px;
            border: 1px solid #aeaeae;
            border-right: none;
            outline: none; }
            .filters .filter.price .form-group.row .wrapper:first-child input:focus {
              outline: none; }
          .filters .filter.price .form-group.row .wrapper:first-child .currency-symbol {
            width: auto;
            float: left;
            height: 34px;
            line-height: 32px;
            border: 1px solid #aeaeae;
            border-left: none; }
        .filters .filter.price .form-group.row .wrapper::after {
          content: attr(data-currency-symbol);
          position: absolute;
          right: 10px;
          top: 6px;
          font-size: 1em;
          color: #575757;
          display: none; }
    .filters .filter.price .noUi-horizontal {
      height: 8px;
      margin: 0 10px; }
      .filters .filter.price .noUi-horizontal .noUi-connect {
        background: #888; }
      .filters .filter.price .noUi-horizontal .noUi-handle {
        width: 18px;
        height: 18px;
        border-radius: 10px; }
        .filters .filter.price .noUi-horizontal .noUi-handle::before {
          display: none; }
        .filters .filter.price .noUi-horizontal .noUi-handle::after {
          display: none; }
    .filters .filter.price html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
      right: -10px;
      left: auto; }
    .filters .filter.filter-color .dropdown ul li span.color.c_10, .filters .filter.filter_color .dropdown ul li span.color.c_10 {
      background: #000000; }
      .filters .filter.filter-color .dropdown ul li span.color.c_10 i.ra-icon.check, .filters .filter.filter_color .dropdown ul li span.color.c_10 i.ra-icon.check {
        color: #FFFFFF !important; }
    .filters .filter.filter-color .dropdown ul li span.color.c_20, .filters .filter.filter_color .dropdown ul li span.color.c_20 {
      background: #FFFFFF; }
    .filters .filter.filter-color .dropdown ul li span.color.c_30, .filters .filter.filter_color .dropdown ul li span.color.c_30 {
      background: #0000FF; }
      .filters .filter.filter-color .dropdown ul li span.color.c_30 i.ra-icon.check, .filters .filter.filter_color .dropdown ul li span.color.c_30 i.ra-icon.check {
        color: #FFFFFF !important; }
    .filters .filter.filter-color .dropdown ul li span.color.c_40, .filters .filter.filter_color .dropdown ul li span.color.c_40 {
      background: #22A2A4; }
    .filters .filter.filter-color .dropdown ul li span.color.c_50, .filters .filter.filter_color .dropdown ul li span.color.c_50 {
      background: #47B734; }
      .filters .filter.filter-color .dropdown ul li span.color.c_50 i.ra-icon.check, .filters .filter.filter_color .dropdown ul li span.color.c_50 i.ra-icon.check {
        color: #FFFFFF !important; }
    .filters .filter.filter-color .dropdown ul li span.color.c_60, .filters .filter.filter_color .dropdown ul li span.color.c_60 {
      background: #FFFF00; }
    .filters .filter.filter-color .dropdown ul li span.color.c_70, .filters .filter.filter_color .dropdown ul li span.color.c_70 {
      background: #F8E7AC; }
    .filters .filter.filter-color .dropdown ul li span.color.c_80, .filters .filter.filter_color .dropdown ul li span.color.c_80 {
      background: #FF8B1C; }
    .filters .filter.filter-color .dropdown ul li span.color.c_90, .filters .filter.filter_color .dropdown ul li span.color.c_90 {
      background: #FF0000; }
      .filters .filter.filter-color .dropdown ul li span.color.c_90 i.ra-icon.check, .filters .filter.filter_color .dropdown ul li span.color.c_90 i.ra-icon.check {
        color: #FFFFFF !important; }
    .filters .filter.filter-color .dropdown ul li span.color.c_100, .filters .filter.filter_color .dropdown ul li span.color.c_100 {
      background: #ED008C; }
    .filters .filter.filter-color .dropdown ul li span.color.c_110, .filters .filter.filter_color .dropdown ul li span.color.c_110 {
      background: #733196; }
      .filters .filter.filter-color .dropdown ul li span.color.c_110 i.ra-icon.check, .filters .filter.filter_color .dropdown ul li span.color.c_110 i.ra-icon.check {
        color: #FFFFFF !important; }
    .filters .filter.filter-color .dropdown ul li span.color.c_120, .filters .filter.filter_color .dropdown ul li span.color.c_120 {
      background: #867326; }
      .filters .filter.filter-color .dropdown ul li span.color.c_120 i.ra-icon.check, .filters .filter.filter_color .dropdown ul li span.color.c_120 i.ra-icon.check {
        color: #FFFFFF !important; }
    .filters .filter.filter-color .dropdown ul li span.color.c_130, .filters .filter.filter_color .dropdown ul li span.color.c_130 {
      background: #B4B4B4; }
    .filters .filter.filter-color .dropdown ul li span.color.c_140, .filters .filter.filter_color .dropdown ul li span.color.c_140 {
      background: #fceabb;
      /* Old browsers */
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, #fceabb 0%, #fccd4d 50%, #f8b500 51%, #fbdf93 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$filter-color-variant-gold-part1', endColorstr='$filter-color-variant-gold-part4',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
    .filters .filter.filter-color .dropdown ul li span.color.c_150, .filters .filter.filter_color .dropdown ul li span.color.c_150 {
      background: #f5f6f6;
      /* Old browsers */
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, #f5f6f6 0%, #dbdce2 21%, #b8bac6 49%, #dddfe3 80%, #f5f6f6 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$filter-color-variant-silver-part1', endColorstr='$filter-color-variant-silver-part5',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
    .filters .filter.filter-color .dropdown ul li span.color.c_160, .filters .filter.filter_color .dropdown ul li span.color.c_160 {
      background: #E1FFFF;
      /* Old browsers */
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, #e1ffff 0%, #e1ffff 7%, #e1ffff 12%, #fdffff 12%, #e6f8fd 30%, #c8eefb 54%, #bee4f8 75%, #b1d8f5 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1ffff', endColorstr='#b1d8f5',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
  .filters .filter-functions {
    padding: 0 .5rem; }
    .filters .filter-functions .more-filter {
      display: block;
      font-weight: bold;
      cursor: pointer;
      border: 1px solid #e3e3e3;
      height: 4rem;
      overflow: hidden; }
      .filters .filter-functions .more-filter button {
        font-style: italic;
        font-weight: 400;
        padding: .5rem .5rem .5rem 1rem;
        height: 4rem;
        line-height: 1;
        white-space: normal;
        text-align: left; }
      .filters .filter-functions .more-filter i.ra-icon {
        font-weight: normal;
        vertical-align: middle; }
      .filters .filter-functions .more-filter i.arrowdown {
        position: absolute;
        right: 1rem;
        top: 1.25rem; }
      .filters .filter-functions .more-filter:hover {
        background-color: #e3e3e3;
        border: 1px solid #aeaeae; }
    .filters .filter-functions .less-filter {
      display: block;
      font-weight: bold;
      cursor: pointer;
      border: 1px solid #e3e3e3;
      background-color: #575757;
      color: #FFFFFF;
      height: 4rem;
      overflow: hidden; }
      .filters .filter-functions .less-filter button {
        font-style: italic;
        font-weight: 400;
        padding: .5rem .5rem .5rem 1rem;
        height: 4rem;
        line-height: 1;
        white-space: normal;
        text-align: left;
        color: #FFFFFF; }
      .filters .filter-functions .less-filter i.ra-icon {
        font-weight: normal;
        vertical-align: middle; }
      .filters .filter-functions .less-filter i.arrowup {
        position: absolute;
        right: 1rem;
        top: 1.25rem; }
      .filters .filter-functions .less-filter:hover {
        background-color: #e3e3e3;
        border: 1px solid #aeaeae;
        color: #575757; }
        .filters .filter-functions .less-filter:hover button {
          color: #575757; }

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -10px;
  left: auto; }

[data-toggle="dropdown"] i.ra-icon {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: rotate(0deg); }

.selected-filters .reset-filter-btn {
  padding: 0 .5rem;
  margin: 2rem 0; }
  .selected-filters .reset-filter-btn .btn-reset-filter {
    border: 1px solid #e3e3e3;
    padding: .5rem 6rem .5rem 1rem;
    font-weight: bold;
    text-align: left;
    overflow: hidden;
    line-height: 1;
    height: 4rem;
    white-space: normal;
    display: flex;
    width: 100%;
    vertical-align: middle;
    align-items: center; }
    .selected-filters .reset-filter-btn .btn-reset-filter .title {
      display: block;
      line-height: 1;
      font-weight: normal; }
    .selected-filters .reset-filter-btn .btn-reset-filter i {
      position: absolute;
      top: 50%;
      right: 1rem;
      transition: .3s;
      transform: translateY(-50%); }
    .selected-filters .reset-filter-btn .btn-reset-filter:hover {
      border: 1px solid #aeaeae;
      background-color: #e3e3e3; }

.product-overview-header {
  margin-top: 2rem;
  margin-bottom: 4rem; }

.category-overview .parent-category-box {
  margin-bottom: 5rem;
  line-height: 2.5rem; }

.category-overview .parent-category-box-small {
  margin-bottom: 5rem;
  line-height: 2.5rem; }

.category-overview .showMoreBox {
  margin-bottom: 5rem; }
  .category-overview .showMoreBox span {
    color: #008297;
    cursor: pointer;
    font-style: italic; }
    .category-overview .showMoreBox span:hover {
      color: #171717; }

.category-overview h2 {
  font-size: 1.4rem; }

.category-overview ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  .category-overview ul li {
    margin: 0;
    padding: 0; }

.category-overview .category-big .category-item-title {
  height: auto; }

.category-overview .category-big .parent-category-box {
  margin-top: 2rem; }

.category-overview .border-subcategories {
  border-bottom: 1px solid #575757;
  margin-bottom: 1rem;
  width: 95%; }

@media (min-width: 768px) {
  .category-overview .line-all-products {
    border-bottom: 2px solid darkgrey; }
    .category-overview .line-all-products:last-child {
      border-bottom: none; }
  .category-overview .list-big-layout {
    -moz-column-count: 2;
         column-count: 2; } }
  @media (min-width: 768px) and (min-width: 992px) {
    .category-overview .list-big-layout {
      -moz-column-count: 3;
           column-count: 3; } }

.search-headline {
  margin-bottom: 2rem; }

.similar-products-headline {
  margin: 4rem 0 2rem 0; }

.choose-correct-article-headline {
  margin: 2rem 0; }

#categoryTeaser .teaserHeadline, #categoryTeaser .teaserContent {
  margin-bottom: 2.5rem; }

#categoryTeaser.row-fullscreen {
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw; }
